
html, body, #root, #app, #app>div {
  height: 100%;
  padding: 0px;
  margin: 0px;
}

body {
  margin: 0;
  width: 100%;
  height: 100%;
  min-height: 100%;
  font-family: 'Roboto';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

* {
  box-sizing: border-box;
}

body {
    -webkit-font-smoothing: antialiased;
}

body, input, button, .toast {
    font-family: Roboto, sans-serif;
}

.toast{
    color: white;
}

.cursor-pointer {
  cursor: pointer;
}
