.auth-panel {
    display: flex;
    justify-content: center;
    padding-left: 15px;
    padding-right: 15px;
    background: #FBFFF0;
    height: 100%;
    padding-top: 93px;
    .main-container {    
        max-width: 400px;
        position: relative;
        display: flex;
        flex-direction: column;
        height: 100%;
        width: 100%;
        padding-bottom: 25px;
        .amplify-button {
            border-radius: 30px;
            cursor:auto
            // background-color: #282828;
        }
        .amplify-input, .amplify-input:focus {
            border-bottom: 1px solid black;
            border-top: none;
            border-left: none;
            border-right: none;
            box-shadow: none;
        }
        .amplify-field-group__outer-end {
            display: none;
        }
        .amplify-button[data-variation='primary'] {
            background-color: #282828;
        }
        div[data-amplify-container] {
            max-width: 400px;
            width: 100% !important;
        }
        div[data-amplify-router] {
            border: none !important;
            box-shadow: none !important;
            background: #FBFFF0;
        }
        .amplify-tabs-item {
            background: #FBFFF0;
        }
        
        .header {
            display: flex;
            align-items: center;
            width: 100%;
            img {
                height: 35px;
                margin-right: 15px;
            }
            .header-text {
                display: flex;
                align-items: baseline;
            }
            .main-caption {
                font-family: 'Inter';
                font-style: normal;
                font-weight: 700;
                font-size: 20px;
                line-height: 24px;
                margin-right: 10px;
                color: #282828;
            }
            .sub-caption {
                font-family: 'Inter';
                font-style: normal;
                font-weight: 500;
                font-size: 14px;
                line-height: 17px;
                /* identical to box height */
                color: #B7B7B7;
            }
        }
    }
}