.eddting-profile {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: absolute;
    transform: translate(-50%, -50%);
    left: 50%;
    right: 50%;
    top: 50%;
    width: 400px;
    height: 100vh;
    z-index: 1;
    background: rgba(92, 92, 92, 0.7);
    backdrop-filter: blur(2px);
    border-radius: 30px;
    outline: none;
  }
  .save-btn-popup {
    margin-top: 30px;
    width: 322px !important;
    height: 48px;
    background: #282828 !important;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
    border-radius: 30px;
    color: #fff;
  }
  
  .text-inputField {
    padding: 15px;
    width: 330px;
    height: 33px;
    background: #ffffff;
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.05);
    border-radius: 30px;
    border: none;
  }
  
  .input-upper-text {
    font-family: "Inter";
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    text-align: center;
    text-transform: uppercase;
  
    color: #ffffff;
  }
  .wrap-input {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .bio-inputFiled {
    height: 80px;
    border-radius: 12px;
  }
  
  .text-inputField:focus-visible {
    outline: none;
  }
  .profile-container {
    font-family: "Inter";
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
  
    height: 100%;
  
    .header,
    .refresh-ment {
      padding-left: 35px;
      padding-right: 35px;
    }
    .top-panel {
      background: #fbfff0;
      padding-bottom: 70px;
      display: flex;
      justify-content: center;
      .top-box {
        max-width: 400px;
      }
    }
    .header {
      margin-top: 93px;
      display: flex;
      align-items: center;
      width: 100%;
      justify-content: space-between;
      img {
        height: 35px;
        margin-right: 15px;
      }
      .header-text {
        display: flex;
        align-items: baseline;
        width: 60%;
      }
      margin-bottom: 30px;
    }
    .main-container {
      background: linear-gradient(180deg, #f0f0f0 36.17%, #bfbfbf 72.83%);
      // max-width: 400px;
      width: 100%;
      position: relative;
      display: flex;
      flex-direction: column;
      height: 100%;
    }
    .sub-container {
      display: flex;
      justify-content: center;
      flex-grow: 1;
    }
    .sub-box {
      max-width: 400px;
      width: 100%;
      display: flex;
      flex-direction: column;
  
      position: relative;
      padding-bottom: 25px;
  
      .domain-container,
      .profile-title,
      .user-container {
        display: flex;
      }
      .domain-container,
      .profile-title {
        img {
          width: 26px;
          height: 26px;
        }
        justify-content: center;
      }
      .domain-container {
        margin-top: 87px;
        .domain-box {
          position: relative;
          background: #d9d9d9;
          border-radius: 30px;
          padding-right: 20px;
          padding-left: 20px;
          padding-top: 4px;
          padding-bottom: 4px;
          font-weight: 300;
          font-size: 14px;
          line-height: 17px;
          img {
            position: absolute;
            right: -34px;
            top: 0;
          }
        }
      }
      .profile-title {
        font-weight: 700;
        font-size: 20px;
        line-height: 24px;
        text-align: center;
        letter-spacing: -0.05em;
        margin-top: 35px;
        .title-content {
          position: relative;
          font-weight: 500;
          font-size: 20px;
          line-height: 24px;
          text-align: center;
          letter-spacing: -0.05em;
  
          color: #999999;
          img {
            position: absolute;
            top: 0;
            right: -34px;
          }
        }
        color: #000000;
      }
      .user-container {
        padding-left: 30px;
        padding-right: 30px;
        align-items: center;
        margin-top: 19px;
        .user-avatar {
          border-radius: 50%;
          background: #d9d9d9;
          color: white;
          width: 77px;
          min-width: 77px;
          height: 77px;
          display: flex;
          justify-content: center;
          align-items: center;
          text-align: center;
          margin-right: 16px;
          overflow: hidden;
          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }
        .user-info {
          display: flex;
          flex-direction: column;
          justify-content: center;
        }
        p {
          margin: 0px;
        }
        .user-name {
          font-weight: 500;
          font-size: 16px;
          line-height: 19px;
          color: #000000;
        }
        .user-influencer {
          font-style: italic;
          font-weight: 400;
          font-size: 13px;
          line-height: 16px;
          color: #828282;
        }
        .user-about {
          font-weight: 400;
          font-size: 13px;
          line-height: 16px;
          color: #585858;
        }
      }
    }
    .main-caption {
      font-family: "Inter";
      font-style: italic;
      font-weight: 400;
      font-size: 12px;
      line-height: 15px;
      text-align: center;
  
      color: #626262;
    }
    .sub-caption {
      font-family: "Inter";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 17px;
      margin-bottom: 40px;
      color: #b7b7b7;
    }
    button {
      border-radius: 30px;
      border: 1px solid #b7b7b7;
      padding-left: 34px !important;
      padding-right: 34px !important;
      padding-top: 7px !important;
      padding-bottom: 7px !important;
      width: 100% !important;
      text-transform: none !important;
    }
    .refresh-ment {
      font-family: "Inter";
      font-style: normal;
      font-weight: 300;
      font-size: 14px;
      line-height: 17px;
      /* identical to box height */
  
      text-align: center;
  
      color: #ff8f27;
      display: flex;
      justify-content: center;
    }
    .d-hidden {
      visibility: hidden;
    }
    .refresh-container {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 140px;
      position: absolute;
      top: -70px;
      left: 0;
      right: 0;
  
      .refresh-box {
        border-radius: 50%;
        border: 6px solid #4cb5ab;
        width: 140px;
        height: 140px;
        display: flex;
        justify-content: center;
        align-items: center;
        box-shadow: 2px 2px 13px #343331;
        font-weight: 500;
        font-size: 16px;
        line-height: 19px;
        text-align: center;
        position: relative;
        color: #d9d9d9;
        background: #fbfff0;
        img {
          width: 90px;
          height: 90px;
          position: absolute;
        }
        .img-rotate {
          animation-name: example;
          animation-duration: 1s;
          animation-timing-function: linear;
          animation-delay: 0.3s;
          animation-iteration-count: infinite;
        }
      }
  
      .color-change {
        animation-name: colorchange;
        animation-duration: 1s;
        animation-timing-function: linear;
        animation-delay: 0.3s;
        animation-iteration-count: infinite;
      }
      @keyframes example {
        0% {
          width: 90px;
          height: 90px;
          transform: rotate(0deg);
        }
        50% {
          width: 85px;
          height: 80px;
          height: 100px;
          transform: rotate(180deg);
        }
        100% {
          width: 90px;
          height: 90px;
          transform: rotate(360deg);
        }
      }
      @keyframes colorchange {
        0% {
          color: #d9d9d9;
          box-shadow: 2px 2px 13px #343331;
        }
        50% {
          color: #ff8f27;
          box-shadow: 2px 2px 12px 2px #343331;
        }
        100% {
          color: #d9d9d9;
          box-shadow: 2px 2px 13px #343331;
        }
      }
    }
    .copyright-container {
      position: relative;
      min-height: 100px;
      flex-grow: 1;
    }
    .copyright {
      font-family: "Inter";
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 15px;
  
      color: white;
      position: absolute;
      bottom: 0;
      left: 35px;
    }
    .live-setting {
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-weight: 400;
      font-size: 20px;
      line-height: 24px;
      color: #555555;
      padding: 0 30px;
    }
  }
  